"use client";

import { useRouter, usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { StaticImageData } from 'next/image';



interface flags {
  enFlag: StaticImageData,
  frFlag: StaticImageData,
}

export default function LanguageSwitcher({enFlag, frFlag}: flags) {
  const router = useRouter();
  const pathname = usePathname();
  const [currentLang, setCurrentLang] = useState('en');

  const languages = [
    { code: 'en', name: 'EN',aria: "English", flag: enFlag },
    { code: 'es', name: 'ES',aria: "Español", flag: enFlag },
    { code: 'fr', name: 'FR',aria: "Français", flag: frFlag }
  ];

  useEffect(() => {
    const segments = pathname.split('/');
    if (segments[1] && languages.some(lang => lang.code === segments[1])) {
      setCurrentLang(segments[1]);
    }
  }, [pathname]);

  const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newLocale = e.target.value;
    Cookies.set('NEXT_LOCALE', newLocale);
    const segments = pathname.split('/');
    segments[1] = newLocale;
    const newPathname = segments.join('/');
    router.push(newPathname);
    window.location.href = newPathname;
  };

  return (
    <label htmlFor="choix-langue" >
      <select id="choix-langue" value={currentLang} onChange={handleLanguageChange}>
        {languages.map((lang) => (
            <option aria-label={lang.aria} key={lang.code} value={lang.code}>
              {lang.name}
            </option>
        ))}
      </select>
    </label>
  );
}
