"use client";

import { Link } from "@nextui-org/link";
import { useState } from "react";
import GdaLogo from "../../../../public/svg/icon-gda-logo.svg";
import enflag from "../../../../public/images/enFlag.png"
import frflag from "../../../../public/images/frFlag.png"
import DropDown from "../../../../public/svg/nav-arrow-down.svg"
import { ThemeSwitcher } from "./ThemeSwitcher/ThemeSwitcher";

import {
  Navbar, 
  NavbarBrand, 
  NavbarContent, 
  NavbarItem, 
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem
} from "@nextui-org/navbar";
import LanguageSwitcher from "./LanguageSwitcher";
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from "@nextui-org/react";

interface MenuItem {
  label: string;
  href: string;
  className?: string;
  dataAdaptifyNavigation: string;
}

export default function MainNavbar({ menuItems, dropdown, dropname, menuItems2 }: { menuItems: MenuItem[], dropdown: MenuItem[], dropname: String, menuItems2: MenuItem[] }) {


  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdownMenu = () => {
    setIsDropdownOpen((prevOpen) => !prevOpen);
  };

  return (
    <Navbar role="navigation" onMenuOpenChange={setIsMenuOpen} className="py-3 max-w-full" isBlurred={!isMenuOpen} classNames={{
      toggleIcon: [
        "text-foreground",
      ],
      wrapper: "max-w-full px-12",
      menu: [
        "pt-5",
      ]
    }}>
      <NavbarContent className="m-0">
          <NavbarMenuToggle
            aria-label="Menu"
            aria-expanded={isMenuOpen ? "true" : "false"}
            className="xl:hidden h-10"
          />
          <NavbarBrand>
            <Link href="/">
              <span className="sr-only"> Accueil </span> 
              <GdaLogo fill="var(--foreground-color)" width="54px" height="50px"/>
              <p className="font-bold text-foreground ml-1 text-sm sm:text-base">GreenDevAccess</p>
            </Link>
          </NavbarBrand>
          
      </NavbarContent>

      <NavbarContent className="hidden xl:flex xl:justify-between xl:gap-4 gap-1 w-full" justify="center">
        {menuItems.map((item, index) => (
          <NavbarItem 
            key={`nav-${item.label}-${index}`} 
            className="h-full"
            isActive
          >
            <Link 
              color="foreground" 
              href={item.href} 
              className={`${item.className} text-foreground-900 xl:text-lg 2xl:text-xl xl:px-2 h-full font-medium hover:bg-foreground-50 hover:border-b-1 hover:border-foreground hover:border-solid`}
              data-adaptify-navigation={item.dataAdaptifyNavigation}
            >
              
                {item.label}

            </Link>
          </NavbarItem>
        ))}
        
          <NavbarItem
          className="h-full"
          isActive>
        <Dropdown closeOnSelect={false} onOpenChange={toggleDropdownMenu}>
          <DropdownTrigger 
            data-adaptify-navigation="3" 
            className="hidden xl:flex xl:justify-between xl:gap-4 gap-1 w-full h-full" 
            aria-expanded={isDropdownOpen ? 'true' : 'false'}
            aria-controls="sous-menu-qui-sommes-nous"
          >
                <Link 
              color="foreground"
              className='cursor-pointer text-foreground-900 xl:text-lg 2xl:text-xl xl:px-2 h-full font-medium hover:bg-foreground-50 hover:border-b-1 hover:border-foreground hover:border-solid'
            >
              
                {dropname}
                <DropDown fill="var(--foreground-color-900)"/>
                

            </Link>
          </DropdownTrigger>
                <DropdownMenu>
                {dropdown.map((item, index) => (
                  <DropdownItem key={`nav-${item.label}-${index}`} href={item.href} data-adaptify-navigation={item.dataAdaptifyNavigation}>
                    <div 
                        color="foreground"
                        className={` text-foreground-900 xl:text-base 2xl:text-lg xl:px-2 h-full font-medium`}
                      >
    
                    {item.label}
                    </div>
            
                  </DropdownItem>
                ))}
                
                </DropdownMenu>
              </Dropdown>
            </NavbarItem>
        {menuItems2.map((item, index) => (
          <NavbarItem 
            key={`nav-${item.label}-${index}`} 
            className="h-full"
            isActive
          >
            <Link 
              color="foreground" 
              href={item.href} 
              className={`${item.className} text-foreground-900 xl:text-lg 2xl:text-xl xl:px-2 h-full font-medium hover:bg-foreground-50 hover:border-b-1 hover:border-foreground hover:border-solid`}
              data-adaptify-navigation={item.dataAdaptifyNavigation}
            >
              
                {item.label}

            </Link>
          </NavbarItem>
        ))}
          
      </NavbarContent>
      <ThemeSwitcher/>
      <LanguageSwitcher frFlag={frflag} enFlag={enflag}/>
      <NavbarMenu>
        {menuItems.map((item, index) => (
          <NavbarMenuItem key={`menu-${item.label}-${index}`}>
            <Link
              className="w-full text-foreground"
              href={item.href}
              size="lg"
            >
              {item.label}
            </Link>
          </NavbarMenuItem>
        ))}
        {dropdown.map((item, index) => (
          <NavbarMenuItem key={`menu-${item.label}-${index}`}>
            <Link
              className="w-full text-foreground"
              href={item.href}
              size="lg"
            >
              {item.label}
            </Link>
          </NavbarMenuItem>
        ))}
        {menuItems2.map((item, index) => (
          <NavbarMenuItem key={`menu-${item.label}-${index}`}>
            <Link
              className="w-full text-foreground"
              href={item.href}
              size="lg"
            >
              {item.label}
            </Link>
          </NavbarMenuItem>
        ))}
      </NavbarMenu>
    </Navbar>
  );
}